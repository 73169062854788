import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import solfront from "../assets/webproject/sol/solfront.webp";
import sol1 from "../assets/webproject/sol/sol-1.webp";
import sol2 from "../assets/webproject/sol/sol-2.webp";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Sol(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--blueberry)";
    document.body.style.setProperty("--scrollbar-background", "var(--violet)");
    document.body.style.setProperty("--scrollbar-border", "var(--violet)");

    useEffect(() => {
        window.scroll(0, 0)
    })
    
    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--blueberry)"
                }}>
                    <h3>Tanztherapie Solveig Bissinger</h3>
                    <h4>HOMEPAGE</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--blueberry)"
                }}>
                    <p>Homepage für das Tanztherapieangebot von Solveig Bissinger. Auf der Webseite soll es Informationen über Therapiemöglichkeiten, Angebote und aktuelle Kurse geben. Anforderungen an das Design sind eine helle, klare und übersichtliche Aufmachung und eine ruhige Wirkung.<br/><br/>Dieses Projekt ist aktuell noch in der Konzeptions- und Planungsphase.</p>
                    <Link link="" />
                </div>
                <div className="pFrontImg solFrontImg" style={{
                    background: "url('" + solfront + "') no-repeat right center",
                    backgroundSize: "cover",
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + sol1 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--black-sand) url('" + sol2 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/6" >Zum nächsten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Sol;