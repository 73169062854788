import "../styles/bal.css";

import LinkBack from "../components/link-back";
import Link from "../components/link";

import jjbfront from "../assets/webproject/jjb/jjb-frontimg.webp";
import jjb1 from "../assets/webproject/jjb/jjb-1.webp";
import jjb2 from "../assets/webproject/jjb/jjb-2.svg";
import jjb3 from "../assets/webproject/jjb/jjb-3.webp";
import jjb4 from "../assets/webproject/jjb/jjb-4.webp";
import jjb5 from "../assets/webproject/jjb/jjb-5.svg";

import React, {useEffect, useRef} from 'react';

import { ReactLenis, useLenis } from 'lenis/react';

function Jjb(props) {
    const lenis = useLenis(({ scroll }) => {})
    
    document.body.style.backgroundColor = "var(--strawberry)";
    document.body.style.setProperty("--scrollbar-background", "var(--violet)");
    document.body.style.setProperty("--scrollbar-border", "var(--violet)");

    useEffect(() => {
        window.scroll(0, 0)
    })

    return <ReactLenis root>
        <div className="bal">
            <LinkBack link="/home">Zurück</LinkBack>

            <div className="pGrid">
                <div className="pHead" style={{
                    backgroundColor: "var(--strawberry)"
                }}>
                    <h3>Jjb Films</h3>
                    <h4>HOMEPAGE & Corporate identity</h4>
                </div>
                <div className="pTextWrapper" style={{
                    backgroundColor: "var(--strawberry)"
                }}>
                    <p>JJB Films ist ein Unternehmen für Imagefilme und Event-Fotografie & -Videografie. Die Webseite soll das Unternehmen vorstellen, bisherige Projekte und das Angebot, sowie Kontaktmöglichkeiten beinhalten. Passend zum Thema ist das zentrale Element des schlanken, dunkel gehaltenen Designs die Fotografie im Hintergrund, die auch die Farbpalette bestimmt.<br/><br/>Mein Aufgabenbereich: Planung und Gestaltung in Adobe Xd, Umsetzung in Html/Css/Javascript</p>
                    <Link link="https://jjb-films.de" >Zur Website</Link>
                </div>
                <div className="pFrontImg" style={{
                    background: "url('" + jjbfront + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15 pFullHeight" style={{
                    background: "var(--paper) url('" + jjb1 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + jjb2 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + jjb3 + "') no-repeat center",
                    backgroundSize: "contain"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + jjb4 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

                <div className="p15" style={{
                    background: "var(--paper) url('" + jjb5 + "') no-repeat center",
                    backgroundSize: "cover"
                }}/>

            </div>

            <div className="pNextLink">
                <Link link="/5" >Zum nächsten Projekt</Link>
            </div>

        </div>
    </ReactLenis>
}

export default Jjb;